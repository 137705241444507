import React from "react"
import { ThemeProvider } from "styled-components"
import theme from "./src/themes/theme"
import { LanguageProvider } from "./src/context/language-context"

export const wrapRootElement = ({ element }) => (
  <LanguageProvider>
    <ThemeProvider theme={theme}>{element}</ThemeProvider>
  </LanguageProvider>
)
