import React, { useState, useEffect, createContext } from "react"

const languages = ["English", "Français"] // "Español"
const languagesShort = ["en", "fr"] // "es"
const defaultState = {
  language: "English",
  languageShort: "en",
  languages,
  languagesShort,
  setCurrentLanguage: () => {},
}

const LanguageContext = createContext(defaultState)

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState("English")
  const [languageShort, setLanguageShort] = useState("en")

  const setCurrentLanguage = lang => {
    setLanguage(lang)
    setLanguageShort(languagesShort[languages.indexOf(lang)])
  }

  return (
    <LanguageContext.Provider
      value={{
        language,
        languageShort,
        languages,
        languagesShort,
        setCurrentLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  )
}

export default LanguageContext
