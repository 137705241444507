const theme = {
  font: {
    primary: "Inter",
    secondary: "Bebas Neue",
  },
  color: {
    primary: "#0A1F3A", // Cobalt 900
    secondary: "white",
    orange: "#F39200",
    // blue: "#1460b4", // borders
    blue: "#185197",
    gray: "#6E869D",
    tangerine: "#F68911",
    tangerine700: "#DE6610",
    tangerine900: "#AE3F01",
    btnDisabled: "#F6891140",
    background1: "#F4F9FD",
    gradient1:
      "linear-gradient(273deg, #00063F -34.35%, #093966 -12.74%, #094580 8.87%, #095298 28.67%, #0C5CA9 52.98%, #116EC7 76.38%, #2E7EE4 97.09%, #3F8DF0 117.8%, #529AF6 138.5%)",
  },
  bp: {
    mobile: "only screen and (min-width: 576px)",
    tablet: "only screen and (min-width: 768px)",
    laptop: "only screen and (min-width: 992px)",
    laptopL: "only screen and (min-width: 1100px)",
    desktop: "only screen and (min-width: 1200px)",
  },
  space: {
    xs: "1rem",
    sm: "2rem",
    med: "3rem",
    lg: "4rem",
    xl: "6rem",
  },
  size: {
    mobile: "576px",
    tablet: "768px",
    laptop: "992px",
    maxWidth: "1240px",
  },
}

export default theme

// Mobile Padding: 20px = 1.25rem
